export default {
    name: 'GoogleMap',
    // props: {
    //     dLocationLatitude: {
    //         type: String,
    //     },
    //     dLocationLongitude: {
    //         type: String,
    //     }
    // },
    data() {
        return {
          center: { lat: 0, lng: 0 },
          currentPlace: null,
          markers: [],
          places: [],
          blockAdd:false,
          zoom:1
        }
      },
      mounted() {
        this.geolocate();
      },
      methods: {
        setPlace(place) {
          this.currentPlace = place;
        },
        addMarker() {
          if (this.currentPlace) {
            const marker = {
              lat: this.currentPlace.geometry.location.lat(),
              lng: this.currentPlace.geometry.location.lng(),
            };

            this.markers.push({ position: marker });
            this.places.push(this.currentPlace);
            this.center = marker;
            this.currentPlace = null;
            this.blockAdd = true
            this.zoom = 15
          }
        },
        emptyUbication(){
            this.markers = []
            this.places = []
            this.center.lat = 0
            this.center.lng = 0
            this.blockAdd = false
            document.getElementsByClassName('inputMap')[0].value = ''
            this.zoom=1
        },
        geolocate: function() {
          navigator.geolocation.getCurrentPosition(position => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          });
        },
      },
      computed:{
        // markers(){
        //     return this.$store.state.markers
        // },
        // place(){
        //     return this.$store.state.place
        // }
      },
      watch:{
        // markers(){
        // },
        // place(){
        //     this.currentPlace = []
        //     this.currentPlace = this.place
        // },
        // currentPlace(){
        //     this.addMarker();
        // }
        // markers(){
        //     if (this.markers > 0) {
        //         this.block = true
        //     }
          
        // }
      }
    };